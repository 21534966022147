import React from "react";

import "./index.scss";

const Hero = ({ title, image }) => {
  return (
    <div className="acq-hero global-x-spacing lg:px-4 relative bg-secondary">
      <div className="max-w-78 mx-auto">
        <h3 className="relative text-center uppercase font-medium tracking-tight text-primary">{title}</h3>
        <img
          src={image.url}
          alt={image.fileName}
          className="acq-hero-background-image absolute left-0 right-0 top-0 bottom-0 w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

import RRenderer from "../../../components/richtextRenderer";

import "./index.scss";

const SummaryButton = ({ label, ctaText, link }) => {
  return (
    <a
      href={link}
      className="flex flex-col items-center justify-center gap-8 p-8 border border-primary text-center hover:bg-primary transition-colors duration-300"
      target="_blank"
      rel="noreferrer"
    >
      <h5 className="font-medium uppercase text-primary">{label}</h5>
      <span className="text-grey-blue font-medium underline underline-offset capitalize">{ctaText}</span>
    </a>
  );
};

const SummarySection = ({
  title,
  text,
  button1Label,
  button1CtaText,
  button1Link,
  button2Label,
  button2CtaText,
  button2Link,
}) => {
  return (
    <div className="global-x-spacing lg:px-4 py-12">
      <div className="max-w-78 mx-auto">
        <h3 className="text-center uppercase font-medium tracking-tight text-primary mb-8">{title}</h3>
        <RRenderer
          data={text}
          className="rich-text-new-bullet-list text-grey-blue"
          config={{
            ul: "list-disc ml-4 lg:ml-6 my-2",
          }}
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-12">
          <SummaryButton label={button1Label} ctaText={button1CtaText} link={button1Link} />
          <SummaryButton label={button2Label} ctaText={button2CtaText} link={button2Link} />
        </div>
      </div>
    </div>
  );
};

export default SummarySection;

import React from "react";

import RRenderer from "../../../components/richtextRenderer";

import "./index.scss";

const PersonCard = ({ image, title, text, logo, variant }) => {
  return (
    <div className="acq-person flex flex-col lg:flex-row items-stretch mx-auto">
      <img
        src={image.url}
        alt={image.fileName}
        className={`acq-person-image ${variant === "right" ? "lg:order-2" : ""}`}
      />
      <div className="px-4 py-6 lg:p-16">
        <h3 className="uppercase font-medium tracking-tight text-secondary mb-8">{title}</h3>
        <RRenderer
          data={text}
          config={{
            p: "text-base font-normal tracking-wide leading-6 rich-text-p-spacing text-grey-blue",
          }}
        />
        <img src={logo.url} alt={logo.fileName} className="acq-person-logo mt-8" />
      </div>
    </div>
  );
};

const PersonsSection = ({
  person1Image,
  person1Title,
  person1Text,
  person1Logo,
  person2Image,
  person2Title,
  person2Text,
  person2Logo,
}) => {
  return (
    <div className="global-x-spacing lg:px-4 py-12">
      <div className="grid gap-24 max-w-78 mx-auto">
        <PersonCard image={person1Image} title={person1Title} text={person1Text} logo={person1Logo} variant="left" />
        <PersonCard image={person2Image} title={person2Title} text={person2Text} logo={person2Logo} variant="right" />
      </div>
    </div>
  );
};

export default PersonsSection;

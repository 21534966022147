import React from "react";

import "./index.scss";

const LinkCard = ({ title, icon, link }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="acq-link-card border border-secondary">
      <h3 className="text-secondary font-medium uppercase">{title}</h3>
      <img src={icon.url} alt={icon.fileName} className="acq-link-card-icon" />
    </a>
  );
};

const LinksSection = ({
  newsTitle,
  newsIcon,
  newsLink,
  presentationTitle,
  presentationIcon,
  presentationLink,
  webcastTitle,
  webcastIcon,
  webcastLink,
}) => {
  return (
    <div className="global-x-spacing lg:px-4">
      <div className="acq-links-grid max-w-78 mx-auto">
        <LinkCard title={newsTitle} icon={newsIcon} link={newsLink} />
        <LinkCard title={presentationTitle} icon={presentationIcon} link={presentationLink} />
        <LinkCard title={webcastTitle} icon={webcastIcon} link={webcastLink} />
      </div>
    </div>
  );
};

export default LinksSection;

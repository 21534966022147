import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../containers/reunion-gold-acquisition/Hero";
import LinksSection from "../containers/reunion-gold-acquisition/LinksSection";
import SummarySection from "../containers/reunion-gold-acquisition/SummarySection";
import VideoSection from "../containers/reunion-gold-acquisition/VideoSection";
import AnnouncementSection from "../containers/reunion-gold-acquisition/AnnouncementSection";
import PersonsSection from "../containers/reunion-gold-acquisition/PersonsSection";
import TransactionHighlightsSection from "../containers/reunion-gold-acquisition/TransactionHighlightsSection";

const ReunionGoldAcquisitionPage = ({ data }) => {
  const pageData = data.contentfulAcqLandingPage || {};

  return (
    <Layout inverted>
      <Seo title="Reunion Gold Acquisition" />
      <Hero title={pageData.heroTitle} image={pageData.heroImage.file} />
      <LinksSection
        newsTitle={pageData.newsTitle}
        newsIcon={pageData.newsIcon.file}
        newsLink={pageData.newsLink}
        presentationTitle={pageData.presentationTitle}
        presentationIcon={pageData.presentationIcon.file}
        presentationLink={pageData.presentationLink}
        webcastTitle={pageData.webcastTitle}
        webcastIcon={pageData.webcastIcon.file}
        webcastLink={pageData.webcastLink}
      />
      <SummarySection
        title={pageData.summaryTitle}
        text={pageData.summaryText}
        button1Label={pageData.summaryButton1Label}
        button1CtaText={pageData.summaryButton1CtaText}
        button1Link={pageData.summaryButton1Link}
        button2Label={pageData.summaryButton2Label}
        button2CtaText={pageData.summaryButton2CtaText}
        button2Link={pageData.summaryButton2Link}
      />
      <VideoSection
        title={pageData.videoTitle}
        text={pageData.videoText}
        isYouTubeVideo={pageData.isYouTubeVideo}
        videoLink={pageData.videoLink}
      />
      <PersonsSection
        person1Image={pageData.person1Image.file}
        person1Title={pageData.person1Title}
        person1Text={pageData.person1Text}
        person1Logo={pageData.person1Icon.file}
        person2Image={pageData.person2Image.file}
        person2Title={pageData.person2Title}
        person2Text={pageData.person2Text}
        person2Logo={pageData.person2Icon.file}
      />
      <TransactionHighlightsSection text={pageData.footnotesText} />
    </Layout>
  );
};

export const query = graphql`
  query ReunionGoldAcquisitionPageQuery($locale: String) {
    contentfulAcqLandingPage(node_locale: { eq: $locale }) {
      heroTitle
      heroImage {
        file {
          fileName
          url
        }
      }
      newsTitle
      newsIcon {
        file {
          fileName
          url
        }
      }
      newsLink
      presentationTitle
      presentationIcon {
        file {
          fileName
          url
        }
      }
      presentationLink
      webcastTitle
      webcastIcon {
        file {
          fileName
          url
        }
      }
      webcastLink
      summaryTitle
      summaryText {
        raw
      }
      summaryButton1Label
      summaryButton1CtaText
      summaryButton1Link
      summaryButton2Label
      summaryButton2CtaText
      summaryButton2Link
      videoTitle
      videoText {
        raw
      }
      isYouTubeVideo
      videoLink
      person1Image {
        file {
          fileName
          url
        }
      }
      person1Title
      person1Text {
        raw
      }
      person1Icon {
        file {
          fileName
          url
        }
      }
      person2Image {
        file {
          fileName
          url
        }
      }
      person2Title
      person2Text {
        raw
      }
      person2Icon {
        file {
          fileName
          url
        }
      }
      footnotesText {
        raw
      }
    }
  }
`;

export default ReunionGoldAcquisitionPage;

import React from "react";

import RRenderer from "../../../components/richtextRenderer";

const TransactionHighlightsSection = ({ text }) => {
  return (
    <div className="global-x-spacing lg:px-4 py-12">
      <div className="max-w-78 mx-auto">
        <RRenderer
          data={text}
          config={{
            p: "text-base font-normal leading-6 tracking-wide text-grey-blue",
            ul: "list-disc ml-4 lg:ml-6 text-grey-blue",
          }}
        />
      </div>
    </div>
  );
};

export default TransactionHighlightsSection;

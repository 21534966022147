import React from "react";

import YouTubeVideo from "../../../components/you-tube-video/YouTubeVideo";
import RRenderer from "../../../components/richtextRenderer";

import "./index.scss";

const VideoSection = ({ title, text, isYouTubeVideo, videoLink }) => {
  return (
    <div className="global-x-spacing lg:px-4 py-12 bg-secondary">
      <div className="grid lg:grid-cols-2 items-center gap-8 lg:gap-24 max-w-78 mx-auto">
        <div>
          <h3 className="uppercase font-medium text-primary tracking-tight mb-8">{title}</h3>
          <RRenderer
            data={text}
            config={{
              p: "text-base font-normal tracking-wide leading-6 rich-text-p-spacing text-grey-blue",
            }}
          />
        </div>
        <YouTubeVideo isYouTubeVideo={isYouTubeVideo} media={videoLink} className="acq-video mx-auto" />
      </div>
    </div>
  );
};

export default VideoSection;
